<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-cierre-novedad"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cerrar Novedades Inspección - {{ inspeccion_id }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div 
            v-if="novedades && novedades.detalles_inspecciones"
            class="modal-body table-responsive"
          >
            <table 
              class="table table-bordered table-striped table-hover table-sm"
              style="font-size: 0.85em;"
            >
              <thead>
                <tr class="text-nowrap">
                  <th>Estado</th>
                  <th>Fecha</th>
                  <th>Sección</th>
                  <th>Item</th>
                  <th>Observación - Valor</th>
                  <th class="text-center">
                    <div class="custom-control custom-checkbox">
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        id="check"
                        v-model="check_cierre_novedad"
                        :disabled="novedades.cierres_novedades_inspecciones.length == novedades.detalles_inspecciones.length"
                        @change="validaAllChecks"
                      />
                      <label for="check" class="custom-control-label"></label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(det, index) in novedades.detalles_inspecciones"
                  :key="index"
                >
                  <td class="text-center">
                    <span 
                      class="badge"
                      :class="
                        det.cierre_novedad_inspeccion
                        ? 'bg-success'
                        : 'bg-secondary'
                      "
                    >
                      {{ det.cierre_novedad_inspeccion ? "Cerrado" : "Pendiente" }}
                    </span>
                  </td>
                  <td class="text-center text-nowrap">{{ novedades.fecha_inicio }} - {{ novedades.fecha_fin }}</td>
                  <td class="text-nowrap">{{ det.secciones_has_items_inspeccion.seccion.nombre }}</td>
                  <td>{{ det.secciones_has_items_inspeccion.item.nombre }}</td>
                  <td>{{ det.valor }}</td>
                  <td class="text-center">
                    <div 
                      v-if="!det.cierre_novedad_inspeccion"
                      class="custom-control custom-checkbox"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="`check_${index}`"
                        :class="
                          $v.det_check.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        v-model="form.det_inspecciones[index]"
                        @change="validaCheck"
                      />
                      <label :for="`check_${index}`" class="custom-control-label"></label>
                    </div>
                    <div 
                      v-if="det.cierre_novedad_inspeccion && det.cierre_novedad_inspeccion.url"
                      class="form-group"
                    >
                      <a
                        target="_blank"
                        class="btn btn-primary btn-sm"
                        :href="uri_docs + det.cierre_novedad_inspeccion.url"
                      >
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div 
            v-if="det_check"
            class="modal-body pt-0"
          >
            <div class="row">
              <div class="form-group col-md-6">
                <label>Observación:</label>
                <textarea
                  v-model="form.observacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.observacion.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label>Adjunto:</label>
                <input
                  type="file"
                  class="form-control-file"
                  ref="file"
                  id="btnFile"
                  @change="getFile"
                  accept="application/msword,application/pdf,application/rtf,image/*"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              v-if="
                (
                  $store.getters.can('hidrocarburos.cierreNovedad.create') || 
                  $store.getters.can('hidrocarburos.inspecciones.cerrarNovedad')
                ) && 
                det_check &&
                !$v.form.$invalid"
              @click="save"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { stringify } from "qs";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CierreNovedadForm",
  components: {},
  data() {
    return {
      inspeccion_id: null,
      novedades: {},
      check_cierre_novedad: false,
      det_check: null,
      fileDoc: null,
      form: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    return {
      det_check: {
        required,
      },
      form: {
        inspeccion_id: {
          required,
        },
        observacion: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex(inspeccion_id){
      const me = this;
      me.$parent.cargando = true;
      me.novedades = {};
      me.form = {};
      me.det_check = null;
      me.check_cierre_novedad = false;
      me.fileDoc = null;
      me.inspeccion_id = inspeccion_id;

      axios
        .get("/api/hidrocarburos/cierreNovedades/" + me.inspeccion_id)
        .then((response) => {
          me.$parent.cargando = false;
          me.novedades = response.data;
          this.getFrom();
        })
        .catch(function(error) {
            me.$parent.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
    }, 

    getFrom(){
      // Se toman el dato det_inspeccion_id 
      let det_inspecciones = [];
      this.novedades.detalles_inspecciones.forEach(() => det_inspecciones.push(false));

      // Se inicia el formulario
      this.form = {
        inspeccion_id: this.novedades.id,
        det_inspecciones,
        observacion: null,
        url: null,
      }
    },

    validaAllChecks(){
      let det_inspecciones = [];
      this.novedades.detalles_inspecciones.forEach(det => 
        det_inspecciones.push(!det.cierre_novedad_inspeccion ? this.check_cierre_novedad : false)
      );
      this.form.det_inspecciones = det_inspecciones;
      this.det_check = this.check_cierre_novedad ? true : null;
    },

    validaCheck(){
      this.det_check = null;
      this.check_cierre_novedad = false;
      this.form.det_inspecciones.forEach(det => {
        if (det) {
          this.det_check = true;
          return;
        }
      });
    },

    getFile(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1024000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    save(){
      if (!this.$v.form.$invalid) {
        this.$parent.cargando = true;

        // Se ajusta el formulario para enviar al back
        let formData = new FormData();
        let det_inspeccion_id = [];
        this.form.det_inspecciones.forEach((det, index) => {
          if (det) { det_inspeccion_id.push(this.novedades.detalles_inspecciones[index].id) }
        });
        formData.append("inspeccion_id", this.form.inspeccion_id);
        formData.append("det_inspeccion_id", JSON.stringify(det_inspeccion_id));
        formData.append("observacion", this.form.observacion);
        formData.append("file", this.fileDoc);

        axios
          .post("/api/hidrocarburos/cierreNovedades", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.$parent.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Ocurrió un error, vuelva a intentarlo...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>
